import React from 'react';
import PropTypes from 'prop-types';
import Header from './header';
import Footer from './footer';

import '../scss/layout.scss';

const Layout = ({ children }) => {
  

  return (
    <div className="antialiased">
      <Header />
      <div>{children}</div>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
