import React, { useEffect, useRef, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Link } from 'gatsby';
import Logo from '../svg/atomic-farmer-logo.svg';
import '../scss/header.scss';

export default function Header () {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  
  const { title: siteTitle } = site.siteMetadata;
  const containerRef = useRef(null);
  const logoRef = useRef(null);

  const [ menuIsOpen, setMenuIsOpen ] = useState(false);

  useEffect(() => {
    animateLogo();
    window.addEventListener('scroll', stickyNav);

    return (()=> {
      window.removeEventListener('scroll', stickyNav);
    })
  }, [])

  const animateLogo = () => {
		const paths = [].slice.call(logoRef.current.querySelectorAll('path.hidden'), 0);
		for(let i = 0; i < paths.length; i++){
			const path = paths[i];
			const length = path.getTotalLength();
			path.style.strokeDasharray = length + ' ' + length;
		  path.style.transitionDelay =  (i*50)+'ms';
      path.style.strokeDashoffset = length;
      path.style.transitionProperty = 'stroke-dashoffset';
			path.style.transitionDuration =  '300ms';
      path.style.transitionTimingFunction= 'ease-out';
      path.classList.remove('hidden');
      path.getBoundingClientRect();
      path.style.strokeDashoffset = 0;
    }
  }

  const stickyNav = () => {
    if(!containerRef.current) {
      return;
    }
		if (window.pageYOffset > 80) {
			containerRef.current.classList.add('header--stuck');
		} else {
			containerRef.current.classList.remove('header--stuck');
		}
	}

  return (
    <header ref={containerRef} className="header">
      <div className="container relative py-4 flex items-center">
        <div ref={logoRef} className="flex-1">
          <Link to="/">
            <Logo style={{
              height: 32
            }} />
            <span className="hidden">{siteTitle}</span>
          </Link>
        </div>
        
        <button onClick={()=>setMenuIsOpen(!menuIsOpen) } type="button" className={`outline-none focus:outline-none header-hamburger ${menuIsOpen ? 'header-hamburger--open': ''}`}>
          <span />
        </button>
    
        {menuIsOpen && (
          <ul className="header-nav">
            <li><Link onClick={()=>setMenuIsOpen(false) } className="block px-4 pt-2" rel="projects" to="/#projects">Projects</Link></li>
            <li><Link onClick={()=>setMenuIsOpen(false) } className="block px-4 pt-2" rel="about" to="/about">About</Link></li>
            <li><Link onClick={()=>setMenuIsOpen(false) } className="block px-4 pt-2 pb-2" rel="contact" to="/contact">Contact</Link></li>
          </ul>
        )}
      </div>
    </header>
  )
}
