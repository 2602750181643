import React from 'react';
import FacebookIcon from '../svg/facebook.svg';
import TwitterIcon from '../svg/twitter.svg';
import LinkedinIcon from '../svg/linkedin.svg';
import SoundIcon from '../svg/sound.svg';

export default function Footer () {
  return (
    <footer className="container py-4 mt-32 md:flex items-center justify-between">
      <ul className="-ml-2">
        <li className="inline-block p-2"><a target="_blank" rel="noopener noreferrer" aria-label="Facebook" href="https://www.facebook.com/atomicfarmer"><FacebookIcon style={{height: `24px`}} /></a></li>
        <li className="inline-block p-2"><a target="_blank" rel="noopener noreferrer" aria-label="Twitter" href="https://twitter.com/atomicfarmer"><TwitterIcon style={{height: `24px`}} /></a></li>
        <li className="inline-block p-2"><a target="_blank" rel="noopener noreferrer" aria-label="LinkedIn" href="https://www.linkedin.com/in/atomicfarmer"><LinkedinIcon style={{height: `24px`}} /></a></li>
        <li className="inline-block p-2"><a target="_blank" rel="noopener noreferrer" aria-label="SouncCloud" href="https://soundcloud.com/atomicfarmer"><SoundIcon style={{height: `24px`}} /></a></li>
      </ul>
      <div className="legal">&copy;{new Date().getFullYear()} Atomic Farmer a.k.a. Richard Lehmann<span className="block md:inline"></span> All Rights Reserved.</div>
    </footer>
  )
}